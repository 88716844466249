
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUsers } from '@/store/admin/getters';
import { dispatchGetUsers } from '@/store/admin/actions';

@Component
export default class AdminUsers extends Vue {
  public headers = [
    {
      text: 'Full Name',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Is Active',
      sortable: true,
      value: 'is_active',
      align: 'center',
    },
    {
      text: 'Is Admin',
      sortable: true,
      value: 'is_superuser',
      align: 'center',
    },
    {
      text: 'Actions',
      sortable: false,
      value: 'id',
      align: 'center',
    },
  ];

  private tableHeight = 0;

  private async mounted() {
    await dispatchGetUsers(this.$store);
  }

  private get users() {
    return readUsers(this.$store);
  }

  private handleResize() {
    this.tableHeight =
      window.innerHeight - /* header */ 64 - /* title */ 64 - /* footer*/ 48;
  }
}
